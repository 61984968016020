import React, { Component } from 'react'

export default class Timeline extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="timeline">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">highlights</span>
                <h2 className="colorlib-heading animate-box">Timeline</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-star" />
                      </div>
                      <div className="timeline-label">
                        <h2>Software Engineering Fellow <span>January - April 2021</span></h2>
                        <p>Completed 500+ hours in a 12-week immersive course applying a variety of programming languages, frameworks and tools to create front-end and back-end applications. Knowledgeable in using databases and incorporating data from third party API’s with a heavy focus on version control by utilizing GitHub. Demonstrated proficiency in project planning by designing user stories, wireframes, ERD’s (entity relationship diagrams</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-star" />
                      </div>
                      <div className="timeline-label">
                        <h2>Learning & Development Professional<span> 2004-2020</span></h2>
                        <p>I lectured at university, led public and private seminars as well as workshops and ongoing training at MNCs and government agencies. All of these grew from a foundation of bringing understanding and self-awareness to leaders and decision makers on how we think, how we progress, and how we excel. To understand ourselves we must have a basic understanding of the range of human intelligences, personalities, and abilities. Through that greater understanding, we all become more effective at that which we are endeavoring to accomplish.</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-5">
                        <i className="icon-star" />
                      </div>
                      <div className="timeline-label">
                        <h2>Das Wanderlust <span> 1998 - 2003</span></h2>
                        <p> Five continents, Twelve countries, 67 provinces, nine languages, four businesses, three monumental career opportunities and several life altering losses. Every life-long friend I have and many of the most valuable lessons I have learned come from this time well spent. I learned so much and in doing so, learned how little I know. Now each day I am curious to see what little bit more I can find out that I did not even know I lacked.</p> 
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-none">
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
