import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                <div className="col-md-12">
                    <div className="about-desc">
                    <span className="heading-meta">m³ = Me, Myself, & I</span>
                    <h2 className="colorlib-heading">We are what we do</h2>
                    <p>I am a software engineering immersive grad from General Assembly. Seeing the way the world is evolving I felt it was time to retrain for a productive future. I love working to help people live more effective lives. My experience is in leading life changing training at MNCs as well as organizing and leading regional, international events. </p>
                    <p>I help teams thrive through self-awareness. I am in the process of codifying the seminars and workshops I have presented over the last decade to offer a free online service for all people in a variety of languages. I call it PEPlabs. PEP stands for "Powerful Extraordinary People". I believe we all are.</p>
                    <p>With nearly 6,000 hours project management experience I am in the process of formalizing it by studying with Project Management International for the Certified Associate of Project Management(CAPM).</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section className="colorlib-about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">New Horizons</span>
                <h2 className="colorlib-heading">Here are some of my projects</h2>
            </div>
            </div>
            <div className="row row-pt-md">
            <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                <span className="icon">
                    <i className="icon-link" /> 
                </span>
                <div className="desc">
                    <h3>Linked_Lists</h3>
                    <p> This is the information page for a project my partner and I are introducing to Y-Combinator.</p>
                    
                    <p> I built this using HTML and CSS</p>
                </div>
                
                <a href="https://linkedlists.io" target="_blank" rel="noopener noreferrer">Linked_Lists</a>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>A work in progress</h3>
                    <p>This is my first step into making mobile apps with React Native </p>
                    <p></p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-6">
                <span className="icon">
                    <i className="icon-diamond" />
                </span>
                <div className="desc">
                    <h3>Memory Meister</h3>
                    <p>This is a proof of concept class project for memory development and enhancement. </p>
                    <p>I built this using Javascript, HTML, CSS, React, and Express.</p>
                    <a href="http://memorymeister.herokuapp.com/board" target="_blank" rel="noopener noreferrer">Memory Meister</a>
                </div>
                </div>
            </div>
            {/*
            <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Dev Ops</h3>
                    <p>Jenkins , Kubernetes , Docker </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                <span className="icon">
                    <i className="icon-layers2" />
                </span>
                <div className="desc">
                    <h3>Graphic Design</h3>
                    <p>My friend knows .. P</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-6">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Digital Marketing</h3>
                    <p>I use Instagram eight hours a day :) </p>
                </div>
                </div>
            </div>
            */}
            </div>
        </div>
        </section>
      </div>
    )
  }
}
